import {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useContext,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react'
import { useAuth } from './auth'
import { getConfig, UserConfig } from '../api'
import { useOnResume } from '../hooks/resume'

const defaultUserConfig: UserConfig = {
  vouchers: {
    count: 0,
    unread: false,
    upcoming: false,
  },
  broadcast: {
    enabled: false,
    title: '',
    description: '',
  },
  registerStaff: false,
}

const userConfigContext = createContext<{
  userConfig: UserConfig
  setUserConfig: Dispatch<SetStateAction<UserConfig>>
  userConfigLoading: boolean
  updateUserConfig: () => Promise<void>
  clear: () => void
} | null>(null)

export function useUserConfig() {
  return useContext(userConfigContext)!
}

export function ProvideUserConfig({ children }: { children: ReactNode }) {
  const auth = useAuth()

  const [userConfig, setUserConfig] = useState<UserConfig>(defaultUserConfig)
  const [userConfigLoading, setUserConfigLoading] = useState<boolean>(true)

  const fetchConfig = async () => {
    try {
      setUserConfigLoading(true)
      setUserConfig(await getConfig())
    } finally {
      setUserConfigLoading(false)
    }
  }

  const fetchConfigIfLoggedIn = useCallback(() => {
    if (auth.user) {
      fetchConfig()
    }
  }, [auth.user])

  useEffect(fetchConfigIfLoggedIn, [fetchConfigIfLoggedIn])
  useOnResume(() => {
    fetchConfigIfLoggedIn()
  })

  const clear = () => {
    setUserConfig(defaultUserConfig)
  }

  return (
    <userConfigContext.Provider
      value={{
        userConfig,
        setUserConfig,
        userConfigLoading,
        updateUserConfig: fetchConfig,
        clear,
      }}
    >
      {children}
    </userConfigContext.Provider>
  )
}
