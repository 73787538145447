export const repeat =
  'Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut'

const BASE_ERRORS = {
  ENET: `Die Netzwerkverbindung ist gestört. ${repeat}.`,
  EREMOTE: `Leider ist der Vorgang aufgrund einer Störung unserer IT-Systeme derzeit nicht möglich. Wir bemühen uns um schnellstmögliche Fehlerbehebung. ${repeat}.`,
}

export function descriptiveLoginError(code: string) {
  // prettier-ignore
  const ERRORS: { [key: string]: string } = {
    EAUTH: 'Die Kundennummer oder das Kennwort stimmen nicht mit den bei uns hinterlegten Daten überein.',
    ...BASE_ERRORS
  }
  return ERRORS[code] || 'Unbekannter Fehler.'
}

export function descriptiveSignupError(code: string, status: number) {
  // prettier-ignore
  const ERRORS: {[key: string]: string} = {
    ERR: 'Es ist ein Fehler aufgetreten.',
    EMISMAT: 'Die angegebenen Daten stimmen nicht mit den bei uns hinterlegten Kundendaten überein.',
    EINVALI: 'Die angegebenen Daten sind unvollständig oder fehlerhaft.',
    EPASWEX: 'Sie sind bereits registriert. Falls Ihnen die Zugangsdaten nicht bekannt sein sollten, können Sie Ihr Kennwort zurücksetzen.',
    EUSERUN: 'Der Kundennummer ist kein Benutzer zugeordnet. Haben Sie sich bereits zur Benutzung der App freigeschaltet?',
    EPREPUN: 'Es existiert keine Sofortkarte mit der angegebenen Kundennummer.',
    EPREPEX: 'Es existiert bereits ein Kundenkonto für die angegebene Kundennummer.',
    ECUSTUN: 'Die Kundennummer ist ungültig.',
    ECUSLOC: 'Der Vorgang konnte vorübergehend nicht abgeschlossen werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
    ENOMAIL: 'E-Mail konnte nicht zugestellt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
    ...BASE_ERRORS
  }

  return ERRORS[code] || `Fehler ${code || status}`
}

export const reviewError = 'Bitte prüfen Sie Ihre Angaben.'
