import {
  IonButton,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonIcon,
} from '@ionic/react'
import { KeyboardEventHandler, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import pick from 'lodash/pick'
import { pencilOutline } from 'ionicons/icons'
import {
  postActivate,
  postRegister,
  postRegisterCardOnly,
  errorFromResponse,
} from '../../lib/api'
import { descriptiveSignupError, reviewError } from '../../lib/errors'
import { trackAppEvent } from '../../lib/analytics'
import { useUserConfig } from '../../lib/providers/userConfig'
import { useLoading } from '../../lib/providers/loading'
import { useAlert } from '../../lib/hooks/alert'
import { useLoginNavigation } from '../../lib/hooks/navigation'
import Header from '../../components/Header'
import { Callout, Warning } from '../../components/Callout'
import CustomerForm, {
  CustomerInputs,
  GENDERS,
  COUNTRIES,
} from '../../components/CustomerForm'
import PasswordInput from '../../components/inputs/PasswordInput'
import ToggleInput from '../../components/inputs/ToggleInput'
import { ContactSupport, RemoteButton } from '../../components/Content'
import { css } from '@emotion/css'
import { sanitizeWhitespaces } from '../../lib/utils'

interface PasswordInputs {
  generatePassword: boolean
  password?: string
  passwordConfirmation?: string
}

// TODO: Enum?
type SignupMode = 'register' | 'registerCardOnly' | 'activate'
type SignupInputs = CustomerInputs & PasswordInputs

interface ActivateError {
  code: string
  message: string
}

enum FormStep {
  Client = 1,
  Password,
}

const SignupPage: React.FC = () => {
  const { mode } = useParams<{ mode: SignupMode }>()
  const { userConfig } = useUserConfig()

  const defaultInputs: SignupInputs = {
    number: '',
    firstName: '',
    lastName: '',
    email: '',
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    terms: false,
    advertisement: false,
    generatePassword: true,
    password: '',
    passwordConfirmation: '',
  }

  const [step, setStep] = useState(FormStep.Client)
  const [error, setError] = useState<ActivateError | null>(null)
  const [activateInputs, setActivateInputs] =
    useState<SignupInputs>(defaultInputs)
  const [alert] = useAlert()
  const history = useHistory()
  const [navigateToLogin] = useLoginNavigation()
  const [, { start, stop }] = useLoading()

  const onContinueClient = (newInputs: CustomerInputs) => {
    const inputs = {
      ...activateInputs,
      ...newInputs,
    }
    const sanitizedInputs: SignupInputs = {
      ...inputs,
      firstName: sanitizeWhitespaces(inputs.firstName),
      lastName: sanitizeWhitespaces(inputs.lastName),
      email: sanitizeWhitespaces(inputs.email),
      street: sanitizeWhitespaces(inputs.street),
      city: sanitizeWhitespaces(inputs.city),
    }
    setActivateInputs(sanitizedInputs)
    setStep(FormStep.Password)
  }

  const onContinueConfirmation = (newInputs: PasswordInputs) => {
    const inputs = {
      ...activateInputs,
      ...newInputs,
    }
    setActivateInputs(inputs)
    onSubmit(inputs)
  }

  const onSubmit: SubmitHandler<SignupInputs> = async (data) => {
    const { number: clientId, email } = data

    // If logged in and register staff, send auth header
    const authorized = userConfig.registerStaff

    start()
    let res: Response
    if (mode === 'register') {
      res = await postRegister(normalize(data), authorized)
    } else if (mode === 'registerCardOnly') {
      res = await postRegisterCardOnly(normalize(data), authorized)
    } else if (mode === 'activate') {
      res = await postActivate(clientId, normalize(data), authorized)
    } else {
      throw new Error('Unknown mode')
    }

    if (res.ok) {
      stop({ success: true })

      if (mode === 'activate') {
        await showConfirmation({
          message: 'Aktivierung abgeschlossen.',
          params: { clientId },
        })
      } else {
        await showConfirmation({
          message:
            userConfig.registerStaff && !email
              ? 'Registrierung abgeschlossen.'
              : 'E-Mail-Adresse bestätigen und Registrierung abschließen.',
          params: { clientId },
        })
      }

      // TODO: mode
      trackAppEvent('activate')
    } else {
      const error = await errorFromResponse(res)
      stop({ success: false })
      console.error(error)
      setError({
        code: error.errno ? `${error.errno}/${error.code}` : error.code,
        message: descriptiveSignupError(error.code, error.status),
      })
      setStep(FormStep.Client)
    }

    function normalize(data: SignupInputs) {
      let submitData: {
        customer: SignupInputs & { dateOfBirth: string }
        password?: string
      } = {
        customer: {
          ...data,
          dateOfBirth:
            dayjs(
              `${data.birthYear}-${data.birthMonth}-${data.birthDay}`
            ).format('YYYY-MM-DD') + 'T00:00:00',
        },
      }

      if (data.generatePassword === false) {
        submitData = { ...submitData, password: data.password }
      }

      return submitData
    }
  }

  const onError: SubmitErrorHandler<SignupInputs> = (errors) => {
    console.error(errors)
    alert(reviewError)
  }

  const onBack = () => {
    setStep(step - 1)
  }

  const showConfirmation = async ({
    message,
    params,
  }: {
    message: string
    params: { clientId: string }
  }) => {
    if (userConfig.registerStaff) {
      navigateToSignup({ message })
    } else {
      await navigateToLogin({
        message,
        params,
      })
    }

    function navigateToSignup({ message }: { message: string }) {
      history.replace('/signup')
      alert(message)
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <Header user={false}>
          {mode === 'register'
            ? 'Neues Kundenkonto'
            : mode === 'registerCardOnly'
            ? 'Neues Kundenkonto (ohne App)'
            : 'Vorhandene Kundenkarten'}
        </Header>
      </IonHeader>
      <IonContent fullscreen>
        <SignupForm
          hidden={step !== FormStep.Client}
          mode={mode}
          values={activateInputs}
          onContinue={onContinueClient}
          onError={onError}
        >
          {error && (
            <div className="ion-padding-top">
              {error.code !== 'EPASWEX' ? (
                <>
                  <Warning>{error.message}</Warning>
                  <p>
                    <small>
                      <ContactSupport
                        action="die Aktivierung"
                        review={error.code !== 'EREMOTE'}
                        error={error}
                      />
                    </small>
                  </p>
                </>
              ) : (
                <>
                  <Callout>{error.message}</Callout>
                  <IonButton
                    fill="outline"
                    expand="block"
                    size="small"
                    routerLink="/reset-password"
                    className={
                      'secondary ' +
                      css`
                        margin-top: var(--ion-padding);
                        margin-bottom: calc(var(--ion-padding) * 2);
                      `
                    }
                  >
                    Kennwort zurücksetzen
                  </IonButton>
                </>
              )}
            </div>
          )}
        </SignupForm>
        <SignupConfirmationForm
          hidden={step !== FormStep.Password}
          values={activateInputs}
          mode={mode}
          onContinue={onContinueConfirmation}
          onError={onError}
          onBack={onBack}
        ></SignupConfirmationForm>
        {/* HACK: Workaround keyboard being positioned over input field or submit button */}
        <div style={{ height: '50vh' }}></div>
      </IonContent>
    </IonPage>
  )
}

const SignupForm: React.FC<{
  hidden: boolean
  mode: SignupMode
  values: CustomerInputs
  onContinue: SubmitHandler<CustomerInputs>
  onError: SubmitErrorHandler<CustomerInputs>
}> = ({ children, hidden = false, mode, values, onContinue, onError }) => {
  const { userConfig } = useUserConfig()

  const registerFields = {
    number: false,
    gender: true,
    firstName: true,
    lastName: true,
    street: true,
    zipCode: true,
    city: true,
    countryNumber: true,
    telephone: true,
    mobilePhone: true,
    email: true,
    birthDate: true,
    birthYear: true,
    birthMonth: true,
    birthDay: true,
    branchNumber: true,
    terms: true,
    advertisement: true,
  }

  const registerFieldsCardOnly = {
    ...registerFields,
    number: true,
  }

  const activateFields = {
    number: true,
    firstName: true,
    lastName: true,
    email: true,
    birthDate: true,
    birthYear: true,
    birthMonth: true,
    birthDay: true,
    advertisement: true,
  }

  // Allow staff to omit email when registering customer with card only
  const requireEmail = !(
    mode === 'registerCardOnly' && userConfig.registerStaff
  )

  let fields: Record<string, boolean>
  if (mode === 'register') {
    fields = registerFields
  } else if (mode === 'registerCardOnly') {
    fields = registerFieldsCardOnly
  } else {
    fields = activateFields
  }

  return (
    <>
      <CustomerForm
        {...{
          hidden,
          requireEmail,
          fields,
          values,
          onSubmit: onContinue,
          onError,
          header: children,
        }}
      >
        <IonButton
          expand="block"
          fill="outline"
          type="submit"
          className="secondary mt-2"
        >
          Weiter
        </IonButton>
        <Link to="/login" className="alternate-action">
          Abbrechen
        </Link>
      </CustomerForm>
    </>
  )
}

const SignupConfirmationForm: React.FC<{
  hidden: boolean
  values: SignupInputs
  mode: SignupMode
  onContinue: SubmitHandler<PasswordInputs>
  onError: SubmitErrorHandler<PasswordInputs>
  onBack: () => void
}> = ({ hidden = false, values, mode, onContinue, onError, onBack }) => {
  const { control, getValues, watch, handleSubmit } = useForm<PasswordInputs>({
    defaultValues: pick(values, [
      'generatePassword',
      'password',
      'passwordConfirmation',
    ]),
    criteriaMode: 'all',
  })

  const watchGeneratePassword = watch('generatePassword')

  const formattedDateOfBirth = dayjs(
    `${values.birthYear}-${values.birthMonth}-${values.birthDay}`
  ).format('DD.MM.YYYY')

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(onContinue, onError)()
    }
  }

  return (
    <form
      hidden={hidden}
      className="ion-padding"
      onSubmit={handleSubmit(onContinue, onError)}
      onKeyDown={handleKeyDown}
      noValidate
    >
      {values.email ? (
        <div className="ion-padding-vertical">
          <Callout>
            Ist Ihre E-Mail Adresse korrekt?{' '}
            {['register', 'activate'].includes(mode) && (
              <>Ihre Zugangsdaten senden wir Ihnen per E-Mail.</>
            )}
          </Callout>
        </div>
      ) : null}
      <table className="table col-2">
        <tbody>
          {/* https://stackoverflow.com/a/53519842 */}
          {values.number ? (
            <tr>
              <td className="label">Kundennummer</td>
              <td>{values.number}</td>
            </tr>
          ) : null}
          {values.gender ? (
            <tr>
              <td className="label">Anrede</td>
              <td>{GENDERS[values.gender]}</td>
            </tr>
          ) : null}
          {values.firstName && values.lastName ? (
            <tr>
              <td className="label">Name</td>
              <td>
                {values.firstName} {values.lastName}
              </td>
            </tr>
          ) : null}
          {values.street ? (
            <tr>
              <td className="label">Straße</td>
              <td>
                {values.street}
                <br />
              </td>
            </tr>
          ) : null}
          {values.zipCode && values.city ? (
            <tr>
              <td className="label">Stadt</td>
              <td>
                {values.zipCode} {values.city}
                <br />
              </td>
            </tr>
          ) : null}
          {values.countryNumber ? (
            <tr>
              <td className="label">Land</td>
              <td>{COUNTRIES[values.countryNumber]}</td>
            </tr>
          ) : null}
          {values.telephone ? (
            <tr>
              <td className="label">Telefon</td>
              <td>
                {values.telephone}
                <br />
              </td>
            </tr>
          ) : null}
          {values.mobilePhone ? (
            <tr>
              <td className="label">Mobil</td>
              <td>
                {values.mobilePhone}
                <br />
              </td>
            </tr>
          ) : null}
          {values.email ? (
            <tr>
              <td className="label">E-Mail</td>
              <td>
                <strong>{values.email}</strong>
                <br />
              </td>
            </tr>
          ) : null}
          {values.birthYear ? (
            <tr>
              <td className="label">Geburtsdatum</td>
              <td>{formattedDateOfBirth}</td>
            </tr>
          ) : null}
        </tbody>
      </table>

      <IonButton
        fill="outline"
        expand="block"
        className="secondary"
        onClick={onBack}
      >
        <IonIcon slot="start" icon={pencilOutline}></IonIcon>
        Bearbeiten
      </IonButton>
      <IonList lines="full" className="mt-2">
        {mode !== 'registerCardOnly' && (
          <>
            <ToggleInput<PasswordInputs>
              {...{ control }}
              name="generatePassword"
              label="Zufälliges Kennwort"
            ></ToggleInput>
            {watchGeneratePassword === false && (
              <PasswordInput<PasswordInputs>
                {...{ control, getValues }}
                rules={{
                  required: 'Kennwort benötigt',
                  minLength: {
                    value: 6,
                    message: 'Kennwort zu kurz (mindestens 6 Zeichen)',
                  },
                }}
                name="password"
                label="Kennwort"
                confirmation={true}
              ></PasswordInput>
            )}
          </>
        )}
        <RemoteButton expand="block" type="submit" className="primary mt-1">
          {['register', 'registerCardOnly'].includes(mode)
            ? 'Registrieren'
            : 'Aktivieren'}
        </RemoteButton>
      </IonList>
    </form>
  )
}

export default SignupPage
