import { IonPage, IonHeader, IonContent, IonButton } from '@ionic/react'
import { css } from '@emotion/css'
import Header from '../../components/Header'
import { useUserConfig } from '../../lib/providers/userConfig'
import { useTenantConfig } from '../../lib/providers/tenantConfig'

const SignupPage: React.FC = () => {
  const { userConfig } = useUserConfig()
  const tenantConfig = useTenantConfig()
  const signup = tenantConfig?.features.signup

  return (
    <>
      <IonPage id="main-content">
        <IonHeader>
          <Header logo={true} user={false}></Header>
        </IonHeader>
        <IonContent
          fullscreen
          className={
            'ion-padding ' +
            css`
              padding: var(--ion-padding);

              h1 {
                margin: 16px 0 24px;
                font-size: 20px;
              }

              h2 {
                margin: 14px 0;
                font-size: 18px;
                text-transform: none;
              }

              p {
                margin: 14px 0 20px;
              }

              hr {
                position: relative;
                margin: 50px -16px;
                height: 1px;
                background-color: var(--ion-color-tertiary);
              }
            `
          }
        >
          <h1>Registrieren</h1>

          <div
            className={css`
              opacity: ${signup?.register ? 1 : 0.5};
            `}
          >
            <h2>Neu bei {tenantConfig?.about.tagName}?</h2>
            <p>
              Neu bei {tenantConfig?.about.tagName} oder noch keine Kundenkarte?
              Hier ganz einfach ein neues Kundenkonto erstellen!
            </p>
            <IonButton
              routerLink="/signup/register"
              fill="outline"
              expand="block"
              className="button secondary"
              disabled={!signup?.register}
            >
              Neues Kundenkonto (mit App)
            </IonButton>
            <p
              className={css`
                margin: 0 !important;
                text-align: center;

                small {
                  color: var(--ion-color-tertiary);
                }
              `}
            >
              <small>Sie erhalten per E-Mail Zugangsdaten für die App.</small>
            </p>

            {userConfig.registerStaff && (
              <>
                <hr />
                <h2>Keine App gewünscht?</h2>
                <p>
                  Sie wünschen eine Kundenkarte in gedruckter Form, aber
                  verzichten auf die App? Hier ein neues Kundenkonto ohne Zugang
                  zur App erstellen!
                </p>
                <IonButton
                  routerLink="/signup/registerCardOnly"
                  fill="outline"
                  expand="block"
                  className="button secondary"
                >
                  Neues Kundenkonto (ohne App)
                </IonButton>
                <p
                  className={css`
                    margin: 0 !important;
                    text-align: center;

                    small {
                      color: var(--ion-color-tertiary);
                    }
                  `}
                >
                  <small>Sofortkarte mit Kundennummer erforderlich.</small>
                </p>
              </>
            )}
          </div>
          {!signup?.register && <p>Kundenkarte erforderlich.</p>}

          <hr />
          <h2>Kundenkarte vorhanden?</h2>
          <p>
            Sie besitzen bereits eine Kundenkarte in gedruckter Form? Dann
            schalten Sie jetzt auch die {tenantConfig?.about.tagName} App frei!
          </p>
          <IonButton
            routerLink="/signup/activate"
            fill="outline"
            expand="block"
            className="button secondary"
          >
            Mit Kundenkarte fortfahren
          </IonButton>
        </IonContent>
      </IonPage>
    </>
  )
}

export default SignupPage
